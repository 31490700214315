import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Button, Avatar, Divider, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useNavigate } from "react-router-dom";

const HtmlTooltip = React.forwardRef<HTMLDivElement, TooltipProps>(function HtmlTooltip(
    { className, ...props },
    ref
) {
    return (
        <Tooltip
            {...props}
            ref={ref}
            classes={{ popper: className }}
        />
    );
});

const StyledHtmlTooltip = styled(HtmlTooltip)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        marginRight: "10px",
    },
}));

export default function Menu() {
    const [userIsAdmin, setUserIsAdmin] = useState(false);
    const [open, setOpen] = useState(false);
    const userId = useSelector((state: RootState) => state.auth.user?.id) || "No ID";
    const userEmail = useSelector((state: RootState) => state.auth.user?.email) || "Guest";
    const firstTwoEmailChars = userEmail.substring(0, 2).toUpperCase();
    const navigate = useNavigate();

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        setUserIsAdmin(false);

        if (userId === 1) {
            setUserIsAdmin(true);
        }
    }, [userId]);

    return (
        <Grid>
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <StyledHtmlTooltip
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                        <React.Fragment>
                            {userIsAdmin &&
                                <React.Fragment>
                                    <Typography variant='subtitle2' sx={{ color: '#2a2a2a', padding: "5px"}}>{userEmail}</Typography>
                                    
                                    <Divider />

                                    <Button variant="text" startIcon={<AddIcon />} onClick={() => navigate('/registration')}>
                                        Új felhasználó
                                    </Button>

                                    <Divider />
                                </React.Fragment>
                            }
                            <Button variant="text" endIcon={<LogoutIcon />} onClick={() => navigate('/logout')}>
                                Kijelentkezés
                            </Button>
                        </React.Fragment>
                    }
                    slotProps={{
                        popper: {
                            disablePortal: true,
                        },
                    }}
                >
                    <Button onClick={handleTooltipOpen} sx={{ padding: "0 0 0 60px"}}><Avatar>{firstTwoEmailChars}</Avatar></Button>
                </StyledHtmlTooltip >
            </ClickAwayListener>
        </Grid>
    )
}