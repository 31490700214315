import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosInstance";
import { Box, Container, Pagination, LinearProgress } from "@mui/material";
import CreateTable from "./Table";
import PDFGenerator from "./PDFGenerator";

interface ReceiptsProps {
    startDateTime: string | null;
    endDateTime: string | null;
    triggerFetch: boolean;
    buyerName: string | null;
    apNumber: string | null;
    receiptType: string | null;
}
export default function Receipts({ startDateTime, endDateTime, triggerFetch, buyerName, apNumber, receiptType }: ReceiptsProps) {
    const [receipts, setReceipts] = useState([]);
    const [pagination, setPagination] = useState({
        current_page: 1,
        per_page: 50,
        total: 0,
        last_page: 1,
    });
    const [loading, setLoading] = useState(false);
    const [numberOfPaginations, setNumberOfPaginations] = useState(0);
    const [blockContent, setBlockContent] = useState<string[]>([]);
    const [contentSortDirection, setContentSortDirection] = useState<string>("asc");
    const [timeSortDirection, setTimeSortDirection] = useState<string>("asc");

    const fetchReceipts = async (page = 1) => {
        setLoading(true);

        try {
            const response = await axiosInstance.post('/getreceipts',
                { startDateTime, endDateTime, page, buyerName, apNumber, receiptType, contentSortDirection, timeSortDirection }
            );

            setReceipts(response.data.data);
            setPagination({
                current_page: response.data.current_page,
                per_page: response.data.per_page,
                total: response.data.total,
                last_page: response.data.last_page,
            });
        } catch (error) {
            console.error("API call failed:", error);
        } finally {
            setLoading(false);
        };
    };

    const handleBlockContent = (data: { clear: Boolean, blockContent: string }) => {
        if (!data.clear) {
            setBlockContent((prev) => [...prev, data.blockContent]);
        } else {
            setBlockContent((prev) => prev.filter(content => content !== data.blockContent))
        }

    };

    const handleBlockContentOrderBy = () => {
        const direction = contentSortDirection === 'asc' ? 'desc' : 'asc';
        setContentSortDirection(direction);
    };

    const handleBlockTimeOrderBy = () => {
        const direction = timeSortDirection === 'asc'? 'desc' : 'asc';
        setTimeSortDirection(direction);
    };

    useEffect(() => {
        if (startDateTime && endDateTime) {
            fetchReceipts();
        };
    }, [triggerFetch, buyerName, apNumber, receiptType, contentSortDirection, timeSortDirection]);

    useEffect(() => {
        const pagNumber = Math.ceil(pagination.total / pagination.per_page);

        setNumberOfPaginations(pagNumber);
    }, [pagination]);

    const handlePageChange = (_: React.ChangeEvent<unknown>, page: number) => {
        fetchReceipts(page);
    };

    return (
        <React.Fragment>
            <Box display="flex" justifyContent="center" padding={2}>
                <Pagination count={numberOfPaginations} page={pagination.current_page} showFirstButton showLastButton onChange={handlePageChange} />
            </Box>

            <Box>
                {
                    loading ? (
                        <Container maxWidth="sm">
                            <LinearProgress />
                        </Container>
                    ) : (
                        <React.Fragment>
                            < CreateTable
                                receipts={receipts ? receipts : []}
                                pagination={pagination}
                                onData={handleBlockContent}
                                contentOrderBy={handleBlockContentOrderBy}
                                timeOrderBy={handleBlockTimeOrderBy}
                                contentSortDirection={contentSortDirection}
                                timeSortDirection={timeSortDirection}
                            />
                            < PDFGenerator
                                content={blockContent ? blockContent : []}
                            />
                        </React.Fragment>
                    )
                }
            </Box>

            <Box display="flex" justifyContent="center" padding={2}>
                <Pagination count={numberOfPaginations} page={pagination.current_page} showFirstButton showLastButton onChange={handlePageChange} />
            </Box>
        </React.Fragment>
    )
}