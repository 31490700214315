import React, { useEffect, useState } from "react";
import { Page, Text, View, Document, Font, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import CourierNewRegular from '../fonts/Courier-New.ttf';
import CourierNewBold from '../fonts/Courier-New-Bold-Italic-font.ttf';
import { Fab, Dialog, DialogContent } from "@mui/material";

interface ContentProp {
    content: string[],
}

Font.register({
    family: 'CourierNew',
    fonts: [
        { src: CourierNewRegular, fontWeight: 'normal' },
        { src: CourierNewBold, fontWeight: 'bold' },
    ],
});

const styles = StyleSheet.create({
    page: { padding: 30, },
    section: { marginBottom: 10 },
    text: { fontSize: 12 },
});

const PDFDocument = ({ content }: ContentProp) => (
    <Document>
        {content.map((item, index) => (
            <Page key={index} size="A4" style={styles.page}>
                <View style={styles.section}>
                    {item.split('\\n').map((line, i) => (
                        <Text key={i} style={{
                            marginBottom: "5px",
                            fontFamily: 'CourierNew',
                            fontSize: '8px',
                        }}>
                            {line.replace(/\\t+/g, match => '\u00A0\u2003'.repeat(match.length * 4))}
                        </Text>
                    ))}
                </View>
            </Page>
        ))}
    </Document>
);
export default function PDFGenerator({ content }: ContentProp) {
    const [openPdf, setOpenPdf] = useState<boolean>(false);
    const [showPdf, setShowPdf] = useState<boolean>(false);

    useEffect(() => {
        if (content.length > 0) {
            setOpenPdf(true);
        } else {
            setOpenPdf(false);
            setShowPdf(false);
        };
    }, [content]);

    return (
        <div>
            <Fab disabled={openPdf ? false : true} onClick={() => setShowPdf(true)} sx={{
                position: 'fixed',
                height: '40px',
                bottom: 10,
                right: 30,
                width: 'fit-content',
                padding: '7px 28px',
                borderRadius: '5px',
                background: '#F8A10E',
                color: '#2a2a2a',
                fontWeight: 400,
            }}>
                <span>PDF generálás</span>
            </Fab>

            <Dialog 
                open={showPdf} 
                onClose={() => setShowPdf(false)}
                PaperProps={{
                    style: {
                        width: '100%',
                        height: '100%',
                    }
                }}
            >
                <DialogContent>
                    <PDFViewer style={{ width: '100%', height: '100%' }}>
                        <PDFDocument content={content} />
                    </PDFViewer>
                </DialogContent>
            </Dialog>
        </div>
    )
}