import { createSlice } from '@reduxjs/toolkit';

interface User {
  id: number;
  name: string;
  email: string;
}

interface AuthState {
  token: string | null;
  user: User | null;
}

const initialState: AuthState = {
  token: localStorage.getItem("auth_token") || null,
  user: localStorage.getItem("auth_user")
    ? JSON.parse(localStorage.getItem("auth_user") as string)
    : null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
      localStorage.setItem("auth_token", action.payload);
    },
    clearToken(state) {
      state.token = null;
      localStorage.removeItem("auth_token");
    },
    setUser(state, action) {
      state.user = action.payload;
      localStorage.setItem("auth_user", JSON.stringify(action.payload));
    },
    clearUser(state) {
      state.user = null;
      localStorage.removeItem("auth_user");
    },
  },
});

export const { setToken, clearToken, setUser, clearUser } = authSlice.actions;

export default authSlice.reducer;