import React, { useEffect, useState } from 'react';
import { Box, Collapse, IconButton, Typography, Checkbox, Button } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/material/styles';
import DOMPurify from 'dompurify';

interface ReceiptProps {
    onData: Function,
    contentOrderBy: Function,
    timeOrderBy: Function,
    contentSortDirection: string,
    timeSortDirection: string,
    receipts: {
        id: number,
        block_date: string,
        block_time: string,
        block_type: string,
        buyer_name: string,
        block_content: string,
        customer_name: string,
        postal_code: number,
        city: string,
        street: string,
        street_type: string,
        house_number: string,
        tax_number: string,
        ap_number: string,
    }[],
    pagination: {
        current_page: number,
        per_page: number,
        total: number,
        last_page: number,
    }
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f1f1f1',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: '100%',
        fontSize: '12px',
        fontFamily: 'CourierNewRegular, monospace',
        border: '1px solid #dadde9',
        padding: '10px',
    },
}));

export default function CreateTable({ receipts, onData, pagination, contentSortDirection, timeSortDirection, contentOrderBy, timeOrderBy }: ReceiptProps) {
    const [openRows, setOpenRows] = useState<{ [key: number]: boolean }>({});

    const handleRowToggle = (id: number) => {
        setOpenRows(prevState => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const handleCheck = (event: any) => {
        const blockContent = event.target.value;

        if (event.target.checked) {
            onData({ clear: false, blockContent: blockContent });
        } else {
            onData({ clear: true, blockContent: blockContent });
        }
    };

    return (
        <Box padding={4}>
            <TableContainer component={Paper} >
                <Table sx={{ '& > *': { borderBottom: 'unset' }, minWidth: '650px', maxWidth: "100%" }}>
                    <TableHead sx={{ background: "#f1f1f1" }}>
                        <TableRow>
                            <TableCell align="left">#</TableCell>
                            <TableCell align="left">Id</TableCell>
                            <TableCell align="left">Pdf</TableCell>
                            <TableCell></TableCell>
                            <TableCell onClick={() => contentOrderBy()} style={{ cursor: 'pointer' }}>
                                Dátum {contentSortDirection === 'asc' ? '🔼' : '🔽'}
                            </TableCell>
                            <TableCell onClick={() => timeOrderBy()} style={{ cursor: 'pointer' }}>
                                Idő {timeSortDirection === 'asc' ? '🔼' : '🔽'}
                            </TableCell>
                            <TableCell align="left">Típus</TableCell>
                            <TableCell align="left">AP szám</TableCell>
                            <TableCell align="left">Vevő neve</TableCell>
                            <TableCell align="left">Tartalom</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {receipts.map((receipt, index) => {
                            const safeHtml = DOMPurify.sanitize(receipt.block_content)

                            return (
                                < React.Fragment key={receipt.id} >
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        className={openRows[receipt.id] ? "expanded" : ""}
                                    >
                                        <TableCell sx={{ width: "10px" }}>{(pagination.current_page - 1) * pagination.per_page + index + 1}.</TableCell>
                                        <TableCell sx={{ width: "10px" }}>{receipt.id}</TableCell>
                                        <TableCell sx={{ width: "10px" }}>
                                            <Checkbox value={receipt.block_content} onChange={(event) => handleCheck(event)} />
                                        </TableCell>
                                        {receipt.customer_name !== "" &&
                                            <TableCell sx={{ background: "#f1f1f1", width: "10px" }}>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="large"
                                                    onClick={() => handleRowToggle(receipt.id)}
                                                    sx={{ padding: "16px" }}
                                                >
                                                    {openRows[receipt.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            </TableCell>
                                        }
                                        {receipt.customer_name === "" &&
                                            <TableCell></TableCell>
                                        }

                                        <TableCell component="th" scope="row">
                                            {receipt.block_date}
                                        </TableCell>
                                        <TableCell align="left">{receipt.block_time.split(':')[0].length < 2 ? 0 + receipt.block_time.split('.')[0] : receipt.block_time.split('.')[0]}</TableCell>
                                        <TableCell align="left">{receipt.block_type}</TableCell>
                                        <TableCell align="left">{receipt.ap_number}</TableCell>
                                        <TableCell align="left">{receipt.buyer_name}</TableCell>
                                        <TableCell align="left">
                                            {receipt.block_content.length > 50 ? (
                                                < span
                                                    dangerouslySetInnerHTML={{
                                                        __html: safeHtml
                                                            .slice(0, 50)
                                                            .replace(/\\n/g, '<br>')
                                                            .replace(/\\t/g, '&emsp;'),
                                                    }}
                                                />
                                            ) : (
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: safeHtml
                                                            .replace(/\\n/g, '<br>')
                                                            .replace(/\\t/g, '&emsp;'),
                                                    }}
                                                />
                                            )}
                                            {receipt.block_content.length > 50 && '...'}
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: safeHtml
                                                                    .replace(/\\n/g, '<br>')
                                                                    .replace(/\\t/g, '&emsp;'),
                                                            }}
                                                        />
                                                    </React.Fragment>
                                                }
                                            >
                                                <Button><VisibilityIcon /></Button>
                                            </HtmlTooltip>
                                        </TableCell>
                                    </TableRow>
                                    {
                                        receipt.customer_name !== "" &&
                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                                                <Collapse in={openRows[receipt.id]} timeout="auto" unmountOnExit>
                                                    <Box sx={{ margin: 1 }}>
                                                        <Typography variant="h6" gutterBottom component="div">
                                                            Számlázási adatok
                                                        </Typography>
                                                        <Table size="small" aria-label="purchases">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Ügyfél</TableCell>
                                                                    <TableCell>ZIP</TableCell>
                                                                    <TableCell align="right">Település</TableCell>
                                                                    <TableCell align="right">Közterület</TableCell>
                                                                    <TableCell align="right">Házszám</TableCell>
                                                                    <TableCell align="right">Adószám</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row">
                                                                        {receipt.customer_name}
                                                                    </TableCell>
                                                                    <TableCell>{receipt.postal_code}</TableCell>
                                                                    <TableCell align="right">{receipt.city}</TableCell>
                                                                    <TableCell align="right">{receipt.street} {receipt.street_type}</TableCell>
                                                                    <TableCell align="right">{receipt.house_number}</TableCell>
                                                                    <TableCell align="right">{receipt.tax_number}</TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box >
    )
}