import axiosInstance from "../../utils/axiosInstance";

export const validateToken = async () => {
  try {
    const response = await axiosInstance.get('/validate-token');
    
    return response.data.message === 'Token is valid';
  } catch (error) {
    return false;
  }
};

export const fetchUserData = async () => {
  try {
    const response = await axiosInstance.get('/validate-token');
    
    return response.data.token;
  } catch (error) {
    return "User datas are not available";
  }
}