import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { validateToken } from "../components/auth/authService";
import { clearToken, clearUser } from "../redux/slices/authSlice";

const ProtectedRoute = ({ children }) => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkToken = async () => {
      if (!token) {
        navigate("/");
        return;
      }

      const isValid = await validateToken(token);
      if (!isValid) {
        dispatch(clearToken());
        dispatch(clearUser());
        navigate("/");
      }
      setIsLoading(false);
    };
    checkToken();

  }, [token, dispatch, navigate]);

  if (isLoading) return <div>Loading...</div>;

  return children;
};

export default ProtectedRoute;
