import axios from "axios";

const axiosInstance = axios.create({
    baseURL: '/api',
    withCredentials: false,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            console.error('Not authorized! Redirecting to login...');
            window.location.href = '/';
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;