import Menu from "../components/Menu";
import DateTimeRangePicker from "../components/DateTimeRangePicker";
import logo from "../assets/BBox_Solutions_logo.svg";
import { Typography } from "@mui/material";

export default function Dashboard() {
    return (
        <div>
            <div className="flex justify-between dashboard-top-line">
                <div><img src={logo} className="dashboard-logo" /></div>
                <Typography variant="h4" color="#FFFFFF">BBOX nyugtaböngésző</Typography>
                <Menu />
            </div>
            <div>
                <DateTimeRangePicker />
            </div>
        </div>
    )
}