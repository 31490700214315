import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosInstance";
import { Theme, useTheme } from '@mui/material/styles';
import { Box, TextField, Container, InputLabel, MenuItem, FormControl } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';

type FiltersProps = {
    startDateTime: string | null;
    endDateTime: string | null;
    buyerName: (keyword: string) => void;
    apNumber: (keyword: string) => void;
    receiptTypes: (keyword: string) => void;
};

interface ReceiptTypesResponse {
    data: string[];
    message: string;
    count: number;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(type: string, selectedTypes: string[], theme: Theme) {
    return {
        fontWeight: selectedTypes.includes(type)
            ? theme.typography.fontWeightMedium
            : theme.typography.fontWeightRegular,
    };
}

export default function Filters({ startDateTime, endDateTime, buyerName, apNumber, receiptTypes }: FiltersProps) {
    const [receiptTypesArr, setReceiptTypesArr] = useState<string[]>([]);
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const theme = useTheme();

    const handleChange = (event: SelectChangeEvent<typeof selectedTypes>) => {
        const {
            target: { value },
        } = event;
        setSelectedTypes(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {
        const fetchReceiptTypes = async () => {
            try {
                const response = await axiosInstance.post<ReceiptTypesResponse>('/getreceipttypes',
                    { startDateTime, endDateTime }
                );

                setReceiptTypesArr(response.data?.data || []);
            } catch (error: any) {
                console.error("Error fetching receipt types:", error);
                if (error.response) {
                    // Server responded with a status code other than 2xx
                    console.error("Response error:", error.response.data);
                } else if (error.request) {
                    // Request was made but no response received
                    console.error("No response received:", error.request);
                } else {
                    // Something happened in setting up the request
                    console.error("Error setting up request:", error.message);
                }
            }
        };

        if (startDateTime && endDateTime) {
            fetchReceiptTypes();
        }
    }, [startDateTime, endDateTime]);

    useEffect(() =>{
        const types = selectedTypes.join(", ");
        
        receiptTypes(types);
    }, [selectedTypes]);

    return (
        <Container maxWidth="lg">
            <Box display="flex" justifyContent="space-between" padding={3}>
                <Box
                    component="form"
                    sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField id="buyername" label="Vevő neve" variant="standard" onChange={(event) => buyerName(event.target.value)} />
                </Box>

                <Box
                    component="form"
                    sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField id="apnumber" label="AP szám" variant="standard" onChange={(event) => apNumber(event.target.value)} />
                </Box>

                <Box>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="blocktype-label">Típus</InputLabel>
                        <Select
                            labelId="blocktype-label"
                            id="blocktype-select"
                            multiple
                            value={selectedTypes}
                            onChange={handleChange}
                            label="Típus"
                        >
                            {receiptTypesArr.map((type) => (
                                <MenuItem
                                    key={type}
                                    value={type}
                                    style={getStyles(type, selectedTypes, theme)}
                                >
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </Container>
    )
}