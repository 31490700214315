import { useState } from "react";
import { Box, TextField, Button, Divider, Container, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from "dayjs";
import Filters from "../components/Filters";
import Receipts from "./Receipts";

export default function DateTimeRangePicker() {
    const [startDateTime, setStartDateTime] = useState<Dayjs | null>(dayjs().add(-1, 'week'));
    const [endDateTime, setEndDateTime] = useState<Dayjs | null>(dayjs());
    const [buyerName, setBuyerName] = useState<string>("");
    const [apNumber, setAPNumber] = useState<string>("");
    const [receiptType, setReceiptType] = useState<string>("");
    const [triggerFetch, setTriggerFetch] = useState(false);

    const handleBuyerNameFilter = (keyword: string) => {
        setBuyerName(keyword);
    };

    const handleAPNumberFilter = (keyword: string) => {
        setAPNumber(keyword);
    };

    const handleReceiptTypeFilter = (keyword: string) => {
        setReceiptType(keyword);
    };

    const handleSearch = () => {
        setTriggerFetch(!triggerFetch);
    };

    return (
        <div>
            <Box id="datetime-box" display="flex" justifyContent="center" position="relative" gap={2} p={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box id="start-datetime-picker" width="fit-content">
                        <DateTimePicker
                            label="Kezdő dátum és idő"
                            value={startDateTime}
                            maxDateTime={dayjs()}
                            onChange={(newValue) => setStartDateTime(newValue)}
                            ampm={false}
                            slots={{ textField: (params) => <TextField {...params} /> }}
                            slotProps={{
                                popper: {
                                    disablePortal: true,
                                    container: document.getElementById("start-datetime-picker"),
                                },
                            }}
                        />
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        -
                    </Box>
                    <Box id="end-datetime-picker" width="fit-content">
                        <DateTimePicker
                            label="Befejező dátum és idő"
                            value={endDateTime}
                            maxDateTime={dayjs()}
                            onChange={(newValue) => setEndDateTime(newValue)}
                            ampm={false}
                            slots={{ textField: (params) => <TextField {...params} /> }}
                            slotProps={{
                                popper: {
                                    disablePortal: true,
                                    container: document.getElementById("end-datetime-picker")
                                },
                            }}
                        />
                    </Box>
                    <Button onClick={handleSearch}>Keresés</Button>
                </LocalizationProvider>
            </Box>

            <Container maxWidth="lg" sx={{ marginTop: 2}}>
                <Divider> <Typography variant="body2" sx={{ color: "#a2a2a2"}}>Szűrők</Typography> </Divider>
            </Container>

            <Box marginBottom={4}>
                <Filters
                    startDateTime={startDateTime ? startDateTime.toISOString() : null}
                    endDateTime={endDateTime ? endDateTime.toISOString() : null}
                    buyerName={handleBuyerNameFilter}
                    apNumber={handleAPNumberFilter}
                    receiptTypes={handleReceiptTypeFilter}
                />
            </Box>

            <Receipts
                startDateTime={startDateTime ? startDateTime.toISOString() : null}
                endDateTime={endDateTime ? endDateTime.toISOString() : null}
                triggerFetch={triggerFetch}
                buyerName={buyerName}
                apNumber={apNumber}
                receiptType={receiptType}
            />
        </div>
    );
}