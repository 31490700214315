import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearToken, clearUser } from '../../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';

export default function LogOut() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
      dispatch(clearToken());
      dispatch(clearUser());
      
      navigate('/');
    }, [dispatch, navigate]);
  
    return null;
}